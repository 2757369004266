/*
    Endeca control class for the search page endeca instance. 
    
*/

var site = site || {};
site.endeca = site.endeca || {};
site.endeca.instances = site.endeca.instances || {};
site.endeca.instances.foundation_finder_dashboard = site.endeca.instances.foundation_finder_dashboard || {};

site.endeca.instances.foundation_finder_dashboard.control = site.endeca.generic.Class.create( site.endeca.control, { 
    initialize: function( args, qs ) {
        this._super( args );
        this.queryString = this.queryString || qs;
        
        if ( this.nodes.resultsContainer.length ) {
            if ( this.queryString ) { this.search(); }
            else { this.displayResults(); }
        }
    },
    
    searchCompleted: function() {
        if ( !this._super() ) { return; }
        
        var that = this;
        
        this.results.products.resultData = [ this.catalogs.product.resultList[0] ];
        this.results.products.displayResults();
        this.results.products.show();
        
        this.processCoremetrics({ productData: this.results.products.resultData });
        
	    this.hasResults = true;
	    
        this.displayResults(); 
    },
    
    processCoremetrics: function( args ) {
        var args = args || {
            productData: []
        };
        
        if ( typeof cmCreatePageviewTag == 'function' ) {
            for ( var i = 0; i < args.productData.length; i++ ) {
                cmCreateProductviewTag(
                    args.productData[i].PRODUCT_ID, 
                    args.productData[i].PROD_RGN_NAME, 
                    args.productData[i].PARENT_CAT_ID, 
                    null
                );
            }
        }
    }
});
